html,
body,
#app {
  min-height: 100%;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  min-height: 100%;
  margin: 0;
}

.main-body {
  margin-top: 5rem;
  -webkit-font-smoothing: antialiased;
}

.container {
  max-width: 85rem;
  width: 100%;
  padding: 1rem;
  margin: auto;
  box-sizing: border-box;
}

.LandbotLivechat-container,
.LandbotLivechat {
  z-index: 999 !important;
}

.containerNoPad {
  width: 100%;
  max-width: 85rem;
  margin: auto;
}

@media only screen and (max-width: 767px) {
  .main-body {
    margin-top: 3.125rem;
  }
}

html {
  font-size: 16px !important;
}

@media only screen and (min-width: 1499px) and (max-width: 1539px) and (min-resolution: 1.16dppx) and (max-resolution: 1.35dppx) {
  html {
    font-size: 14.8px !important;
  }
}

@media only screen and (min-width: 1370px) and (max-width: 1499px) and (min-resolution: 0.9dppx) and (max-resolution: 1.15dppx) {
  html {
    font-size: 16px !important;
  }
}

@media only screen and (min-width: 1370px) and (max-width: 1499px) and (min-resolution: 1.16dppx) and (max-resolution: 1.35dppx) {
  html {
    font-size: 13.5px !important;
  }
}

@media only screen and (min-width: 1370px) and (max-width: 1499px) and (min-resolution: 1.36dppx) and (max-resolution: 1.65dppx) {
  html {
    font-size: 12.5px !important;
  }
}

@media only screen and (max-width: 1369px) and (orientation: landscape) {
  html {
    font-size: 13.5px !important;
  }
}

@media only screen and (min-resolution: 1.8dppx) and (max-resolution: 2.8dppx) {
  html {
    font-size: 15.7px !important;
  }
}

@media only screen and (min-resolution: 2.8dppx) and (max-resolution: 3dppx) {
  html {
    font-size: 15.5px !important;
  }
}

@media only screen and (min-resolution: 3dppx) and (max-resolution: 3.1dppx) {
  html {
    font-size: 14.7px !important;
  }
}

@media only screen and (min-resolution: 3.1dppx) and (max-resolution: 3.2dppx) {
  html {
    font-size: 13.9px !important;
  }
}

@media only screen and (min-resolution: 3.2dppx) and (max-resolution: 3.5dppx) {
  html {
    font-size: 13.1px !important;
  }
}